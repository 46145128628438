import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createRecordWithHook } from "@ui/ComponentUtils/blueprintAPIs";
import { updateRecordWithHook } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";
import { getSingleRecordWithHook } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";
// import Emitter from "@ui/Utils/CustomEventEmitter";

import enums from "helpers/enums";

const initialState = {
  overallRemarks: undefined,
  items: [], // Each item: { product: {}, quantity: 1 }
  code: null, // To track the cart code if persisted in the database
  status: "idle", // 'idle' | 'loading' | 'failed'
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateCartItems: (state, action) => {
      state.items = action.payload;
    },
    clearCart: (state) => {
      state.items = [];
    },
    updateOverallRemarks: (state, action) => {
      state.overallRemarks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(initializeCart.fulfilled, (state, action) => {
        state.status = "idle";
        state.items = action.payload?.items || state.items;
        state.code = action.payload?.code || state.code;
      })
      .addCase(initializeCart.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(syncCartWithDB.pending, (state) => {
        state.status = "loading";
      })
      .addCase(syncCartWithDB.fulfilled, (state, action) => {
        state.status = "idle";
        state.code = action.payload.code;
        state.items = action.payload.items;
      })
      .addCase(syncCartWithDB.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// Thunk to fetch cart data (from API or fallback to local state)
export const initializeCart = createAsyncThunk(
  "cart/initializeCart",
  async ({}, { getState, dispatch, rejectWithValue }) => {
    try {
      const user = getState().user;

      // Skip initialization if conditions are not met
      if (user?._id) {
        // Fetch cart from the database
        const cartObj = await getSingleRecordWithHook(enums.models.linkItCarts);

        if (cartObj?.code) {
          // Update state with fetched cart
          return {
            items: cartObj.items || [],
            code: cartObj?.code,
            // overallRemarks: cartObj.overallRemarks,
          };
        } else {
          // Fallback logic if API fails
          return {
            items: getState().cart.items, // Use existing local state
            code: null,
          };
        }
      }
    } catch (err) {
      // Handle API failure gracefully
      return rejectWithValue("Failed to initialize cart.");
    }
  }
);

// Thunk to sync cart with the database
export const syncCartWithDB = createAsyncThunk(
  "cart/syncCartWithDB",
  async ({ items, updatingIndex }, { getState, rejectWithValue, dispatch }) => {
    try {
      const {
        code,
        // overallRemarks
        items: ogItems,
      } = getState().cart;

      if (code) {
        // Update existing cart in the database
        const body = {
          code: code,
          items,
          //   overallRemarks,
        };

        if (updatingIndex >= 0) {
          delete body.items;
          body[`items.${updatingIndex}`] = items[updatingIndex];
        }

        // have not used await here because, we need items state to be updated when user adds to cart
        updateRecordWithHook(
          enums.models.linkItCarts,
          body,
          {},
          "emptyResponse=true"
        );
        return { code, items };
      } else {
        // Create a new cart in the database
        const res = await createRecordWithHook(enums.models.linkItCarts, {
          items,
          //   overallRemarks,
        });
        return { code: res.code, items };
      }
    } catch (err) {
      // Not handled yet.. API will never fail in middle, and it returns in above try even without await in update API
      // const getNewItems = (items, ogItems) => {
      //   const existingStyleNos = new Set(
      //     ogItems.map((item) => item.product.styleNo)
      //   );
      //   return items
      //     .filter((item) => !existingStyleNos.has(item.product.styleNo))
      //     .map((item) => item.product.styleNo)
      //     .join(", ");
      // };

      // Emitter.emit(
      //   "alert_error",
      //   `Add to Cart Failed & Reset for ${getNewItems(items, ogItems)} Designs.`
      // );
      return rejectWithValue(err.message);
    }
  }
);

export const clearAndSyncCart = () => async (dispatch, getState) => {
  dispatch(clearCart()); // Clear the cart locally
  await dispatch(syncCartWithDB({ items: [] })); // Sync the empty cart to the database
};

export const {
  addItemToCart,
  removeItemFromCart,
  clearCart,
  updateOverallRemarks,
  updateCartItems,
} = cartSlice.actions;
export default cartSlice.reducer;
