import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import commonEnums from "@ui/Utils/commonEnums";

const initialState = []; // Store gold pricing data

// Async thunk to fetch gold pricing
export const fetchGoldPricing = createAsyncThunk(
  "metals/fetchGoldPricing",
  async (_, { rejectWithValue }) => {
    try {
      const goldPricingList = await search(
        commonEnums.models["data-engine"].goldPricing
      );
      return goldPricingList || [];
    } catch (error) {
      console.error("Error fetching gold pricing:", error);
      return rejectWithValue(error.message);
    }
  }
);

const metalsSlice = createSlice({
  name: "metals",
  initialState,
  reducers: {
    clearMetals: () => initialState, // Reset to empty array
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGoldPricing.fulfilled, (state, action) => {
      return action.payload; // Replace state with fetched gold pricing data
    });
  },
});

export const { clearMetals } = metalsSlice.actions;
export default metalsSlice.reducer;
