import React, { useEffect, useRef, useState } from "react";
import { Button, Grid } from "@mui/material";
// import { formatIndianNumber, roundOff } from "@ui/Utils/helper";
import ProductCard from "../ProductCard";
import OrdersTable from "./OrdersTable";
import CartHeader from "./CartHeader";
import { createRecordWithHook } from "@ui/ComponentUtils/blueprintAPIs";
import Emitter from "@ui/Utils/CustomEventEmitter";
import enums from "helpers/enums";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAndSyncCart,
  updateOverallRemarks,
} from "redux/linkitSlices/cartSlice";
import { unshiftOrder } from "redux/linkitSlices/orderSlice";
import _ from "lodash";
import { useMediaQuery, useTheme } from "@mui/material";
import commonEnums from "@ui/Utils/commonEnums";

const Cart = ({}) => {
  const dispatch = useDispatch();
  const cartObj = useSelector((state) => state.cart);
  const orders = _.cloneDeep(useSelector((state) => state.orders) || []);
  const theme = useTheme();
  const masterData = {
    goldPricingList: useSelector((state) => state.metals) || [],
    diamondPricingList: useSelector((state) => state.diamonds) || [],
  };

  const cart = cartObj.items;
  const overallRemarks = cartObj.overallRemarks;

  let gridView;

  let isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const ordersRef = useRef(null);
  const [isTableOutOfView, setIsTableOutOfView] = useState(false);

  // const isMobile = useMediaQuery("(max-width:600px)");

  const scrollToOrders = () => {
    ordersRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsTableOutOfView(!entry.isIntersecting); // Update state when table is not visible
      },
      { threshold: 0.1 } // Trigger when at least 10% of the table is visible
    );

    if (ordersRef?.current) {
      observer.observe(ordersRef.current);
    }

    return () => {
      if (ordersRef?.current) {
        observer.unobserve(ordersRef.current);
      }
    };
  }, []);

  const clearMyCart = () => {
    if (window.confirm("Clear Cart: Are you sure, all items will be lost?")) {
      dispatch(clearAndSyncCart());
    }
  };

  const placeOrder = async () => {
    try {
      if (window.confirm("PLACE ORDER: confirm?")) {
        const res = await createRecordWithHook(enums.models.linkItOrders, {
          items: cart,
          overallRemarks: overallRemarks,
        });

        // setCart([]);
        dispatch(clearAndSyncCart());
        dispatch(updateOverallRemarks());
        dispatch(unshiftOrder(res));
        Emitter.emit("alert_success", "Order Placed Successfully");
      }
    } catch (err) {
      Emitter.emit("alert_error", "Contact Admin, Invalid Products Found");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  /*
  const downloadPdf = async (order) => {
    try {
      const filteredItems = order.items;

      let totalGrossWeight = 0,
        totalNetWeight = 0,
        totalQuantity = 0;

      const pdfProducts = filteredItems.map((item) => {
        item = item || {};
        item.product = item.product || {};

        totalGrossWeight += Number(item.product?.grossWeight) || 0;
        totalNetWeight += Number(item.product?.netWeight) || 0;
        totalQuantity += Number(item.quantity) || 0;

        return {
          styleNo: item.product?.styleNo,
          grossWeight: (Number(item.product?.grossWeight) || 0).toFixed(3),
          netWeight: (Number(item.product?.netWeight) || 0).toFixed(3),
          purity: item.product?.purity?.value
            ? item.product?.purity?.value + " KT"
            : "",
          color: item.product?.color,

          adminRemarks: item.product?.remarks,
          dueDate: item?.dueDate ? formatDateToRead(item?.dueDate) : "",

          image: getProductImageUri(item.product || {}),
          quantity: item?.quantity,

          motiWeight: item?.product?.motiWeight,
          kundanWeight: item?.product?.kundunWeight,
        };
      });

      const orderDate = order.dateCreated
        ? formatDateToRead(order.dateCreated)
        : "";

      const res = await getQuotationPdfReport({
        data: {
          vendorCode: "",
          remarks: order.overallRemarks,
          orderId: order.orderId,
          orderDate: orderDate,
          products: pdfProducts,

          totalGrossWeight: totalGrossWeight.toFixed(3),
          totalNetWeight: totalNetWeight.toFixed(3),
          totalQuantity: totalQuantity,
          customerName:
            order.createdBy?.firstName + " " + order.createdBy?.lastName,
        },
        pdfTitle: "LINK it Order",
        template: "Template-3",
      });

      generatePDF(res, order.orderId);
    } catch (err) {
      console.log(err);
    }
  }; */

  return (
    <div
      className="cart"
      style={{
        marginTop: "-16px",
      }}
    >
      <CartHeader
        cart={cart}
        overallRemarks={overallRemarks}
        setOverallRemarks={(value) => dispatch(updateOverallRemarks(value))}
      />
      {isTableOutOfView && (
        // <Tooltip title="swipe it">
        <button
          onClick={scrollToOrders}
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            // backgroundColor: "#4CAF50",
            color: "white",
            padding: "10px 15px",
            border: "none",
            borderRadius: "50%",
            cursor: "pointer",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
            zIndex: 0,
            userSelect: "none",
          }}
        >
          🎯
        </button>
        // </Tooltip>
      )}

      <Grid container style={{ marginTop: "24px" }} spacing={2}>
        {cart.length ? (
          <Grid item xs={4} lg={2} style={{ marginBottom: "24px" }}>
            <Button
              onClick={clearMyCart}
              variant="contained"
              color="primary"
              className="place-order-button"
              onMouseLeave={(event) => {
                // if (!event.target.disabled) {
                event.target.style.backgroundColor =
                  commonEnums.colorTheme.secondaryLight; // Swap background color on hover
                event.target.style.color = "grey";
                // }
              }}
              onMouseEnter={(event) => {
                // if (!event.target.disabled) {
                event.target.style.backgroundColor =
                  commonEnums.colorTheme.secondary; // Original background color
                event.target.style.color = "white";
                // }
              }}
              style={{
                backgroundColor: commonEnums.colorTheme.secondaryLight,
                color: "grey",
                borderRadius: "5px",
              }}
            >
              {isMobile ? "Clear" : "Clear Cart"}
            </Button>
          </Grid>
        ) : null}

        <Grid item xs={cart.length ? 8 : 12} lg={cart.length ? 10 : 12}>
          <Button
            onClick={placeOrder}
            variant="contained"
            disabled={!cart.length}
            color="primary"
            className="place-order-button"
            onMouseLeave={(event) => {
              if (!event.target.disabled) {
                event.target.style.backgroundColor = "#075985"; // Swap background color on hover
                event.target.style.color = "#EFF6FF";
              }
            }}
            onMouseEnter={(event) => {
              if (!event.target.disabled) {
                event.target.style.backgroundColor = "#EFF6FF"; // Original background color
                event.target.style.color = "#075985";
              }
            }}
            style={{
              backgroundColor: !cart.length ? "#e0e0e0" : "#075985", // Gray background when disabled
              color: !cart.length ? "#9e9e9e" : "#EFF6FF", // Lighter text color when disabled
              cursor: !cart.length ? "not-allowed" : "pointer", // Change cursor when disabled
              borderRadius: "5px",
            }}
          >
            Place Order
          </Button>
        </Grid>

        <Grid
          container
          style={{
            padding: "4px",
          }}
        >
          {cart.map((item, index) => (
            <Grid
              item
              // gridView ? 6
              xs={12} // 2 columns in grid view on mobile, 1 in list view
              sm={12} // Same for small screens
              md={gridView ? 3 : 6} // 4 columns in grid view on medium, 2 in list view
              lg={gridView ? 2 : 4} // 6 columns in grid view on large, 2 in list view
              key={index}
              // style={{ marginBottom: "24px" }}
            >
              <ProductCard
                isMobile={isMobile}
                product={item.product || {}}
                cart={cart}
                cartItem={item}
                masterData={masterData}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <div style={{ marginTop: "48px" }}>
        <div style={{ borderTop: "2px solid #ddd", marginBottom: "200px" }}>
          <OrdersTable orders={orders} ordersRef={ordersRef} />
        </div>
      </div>
      <style jsx>{`
        .cart {
          display: flex;
          flex-direction: column;
          padding: 6px;
        }

        .cart-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
        }

        .cart-title {
          font-weight: 600;
          color: #5e5e5e;
          letter-spacing: 0.5px;
          font-size: 2rem;
        }

        .total-stats {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          margin-bottom: 16px;
        }

        .stat {
          flex: 1 1 20%;
          margin: 0;
        }

        .place-order-button {
          padding: 8px 16px;
          width: 100%;
          text-transform: none;
          font-size: 1rem;
          font-weight: bold;
          margin-top: 16px;
        }

        @media (max-width: 600px) {
          .cart-header {
            flex-direction: column;
            align-items: flex-start;
          }

          .total-stats {
            flex-direction: column;
          }
        }
      `}</style>
    </div>
  );
};

export default Cart;
