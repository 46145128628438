import commonEnums from "@ui/Utils/commonEnums";
import { useDispatch, useSelector } from "react-redux";
import { syncCartWithDB } from "redux/linkitSlices/cartSlice";

const customizableFields = ["color", "purity", "size", "screw", "remarks"];
const diaCustomFields = ["color", "clarity"];

export const isCustomizationExist = (customization) => {
  return Object.keys(customization || {}).some((key) => {
    return (
      key !== "jewelPrice" &&
      key !== "jewelPriceBreakup" &&
      !(Array.isArray(customization[key]) && customization[key].length === 0) &&
      (key === "diamondDetails"
        ? Object.keys(customization.diamondDetails?.[0] || {}).some(
            (diaKey) =>
              diaCustomFields.includes(diaKey) &&
              customization.diamondDetails?.[0][diaKey]
          )
        : customization[key] || customization[key] === null)
    );
  });
};

export const isCustomizationMatch = (item, customization) => {
  if (!item.isCustomized) {
    return !isCustomizationExist(customization);
  }

  // Check all customizable fields
  for (let cusField of customizableFields) {
    if (cusField === "purity") {
      if (
        // customization.purity?.percent &&
        (item.purity?.percent || customization.purity?.percent) &&
        item.purity?.percent !== customization.purity?.percent
      ) {
        return false;
      }
    } else {
      const customValue = customization[cusField];
      const itemValue = item[cusField];

      if (
        (itemValue !== undefined || customValue !== undefined) &&
        itemValue !== customValue
      ) {
        return false;
      }
    }
  }

  // Check all diamond customization fields
  for (let cusField of diaCustomFields) {
    if (
      (item.diamondDetails?.[0]?.[cusField] !== undefined ||
        customization.diamondDetails?.[0]?.[cusField] !== undefined) &&
      item.diamondDetails?.[0]?.[cusField] !==
        customization.diamondDetails?.[0]?.[cusField]
    ) {
      return false;
    }
  }

  return true;
};

const useCartActions = () => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.cart.items);

  const handleAddToCart = async (cartItem, updateQuantity = false) => {
    try {
      const customization = {
        purity: cartItem.purity,
        color: cartItem.color,
        remarks: cartItem.remarks?.toString().trim(),
        size: cartItem.size,
        screw: cartItem.screw?.toString().trim(),
        setting: cartItem.setting?.toString().trim(),
        grossWeight: cartItem.grossWeight,
        specificEngraving: cartItem.specificEngraving?.toString().trim(),
        diamondDetails: cartItem.diamondDetails,
      };

      // Directly update items array
      let updatingIndex = items.findIndex((item) => {
        return (
          item.product._id === cartItem.product._id &&
          (item.isCustomized
            ? isCustomizationMatch(item, customization)
            : !isCustomizationExist(customization))
        );
      });

      const updatedItems = [...items];

      if (updatingIndex === -1) {
        updatedItems.push({
          ...cartItem,
          product: cartItem.product,
          quantity: cartItem.quantity || 1,
          division: cartItem.product.division?._id
            ? cartItem.product.division
            : undefined,

          isCustomized: isCustomizationExist(customization)
            ? commonEnums.itemCustomization.product
            : undefined,
        });

        updatingIndex = updatedItems.length - 1;
      } else {
        // Update existing item

        updatedItems[updatingIndex] = {
          ...updatedItems[updatingIndex],
          quantity: updateQuantity
            ? Number(cartItem.quantity)
            : Number(updatedItems[updatingIndex].quantity) +
              Number(cartItem.quantity || 1),
        };

        // Update customization
        if (isCustomizationExist(customization)) {
          updatedItems[updatingIndex].isCustomized =
            commonEnums.itemCustomization.product;
        } else {
          updatedItems[updatingIndex].isCustomized = undefined;
        }
      }

      // Update the Redux state
      //   dispatch(updateCartItems(updatedItems));

      // Sync with the database
      dispatch(syncCartWithDB({ items: updatedItems, updatingIndex }));
    } catch (err) {
      console.error("Error adding to cart:", err);
    }
  };

  const handleRemoveFromCart = async (
    cartItem,
    { quantityOnly = false } = {}
  ) => {
    try {
      const customization = {
        purity: cartItem.purity,
        color: cartItem.color,
        remarks: cartItem.remarks?.toString().trim(),
        size: cartItem.size,
        screw: cartItem.screw?.toString().trim(),
        setting: cartItem.setting?.toString().trim(),
        grossWeight: cartItem.grossWeight,
        specificEngraving: cartItem.specificEngraving?.toString().trim(),
        diamondDetails: cartItem.diamondDetails,
      };

      let cartItems = [...items]; // Create a copy of the cart to modify

      // Find the index of the item to be removed
      const itemIndex = cartItems.findIndex((item) => {
        return (
          item.product?._id === cartItem.product?._id &&
          (item.isCustomized
            ? isCustomizationMatch(item, customization)
            : !isCustomizationExist(customization))
        );
      });

      if (itemIndex !== -1) {
        if (quantityOnly) {
          cartItems[itemIndex] = {
            ...cartItems[itemIndex],
            quantity: Number(cartItems[itemIndex].quantity) - 1,
          };

          if (cartItems[itemIndex].quantity === 0) {
            cartItems.splice(itemIndex, 1);
          }
        } else {
          // Remove the item at the found index
          cartItems.splice(itemIndex, 1);
        }
      }

      // Update the Redux state
      //   dispatch(updateCartItems(cartItems));

      // Sync with the database
      dispatch(syncCartWithDB({ items: cartItems }));
    } catch (err) {
      console.error("Error removing from cart:", err);
    }
  };

  return {
    handleAddToCart,
    handleRemoveFromCart,
  };
};

export default useCartActions;
