import React, { useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  Box,
  IconButton,
  TextField,
  Typography,
  Button,
  Tooltip,
  Popper,
  InputAdornment,
} from "@mui/material";
import { handleQuantityChange } from "./ECommerce";
import { Add, Remove, Check as CheckIcon } from "@mui/icons-material";
import {
  formatIndianNumber,
  formatWeight,
  getCompressedImageUrl,
  imageExpired,
  loadingGif,
  oopsNotFound,
  roundOff,
  userDetails,
} from "@ui/Utils/helper";
import { FullscreenPreview } from "@ui/MuiComponents/Carousel/ProductDetailPopup";
import Emitter from "@ui/Utils/CustomEventEmitter";
import LazyLoad from "@ui/Actions/LazyLoad/index";
import enums from "helpers/enums";
import Autocomplete from "@mui/material/Autocomplete";
import commonEnums from "@ui/Utils/commonEnums";
import MyDetailDrawer from "@ui/Figmas/DetailDrawer/MyDetailDrawer";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ReadMore from "@ui/Actions/ReadMore/ReadMore";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import Label from "@ui/components/Label";
import { attachJewelPrice } from "@ui/Utils/jewelPriceCalculator";
import { Grid } from "@material-ui/core/index";
import "./card.css";
import useCartActions, {
  isCustomizationExist,
  isCustomizationMatch,
} from "./Cart/actions";
import store from "redux/store";
import { useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

// import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
// import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { signStoredObjects } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";

const initialQuantity = 0,
  initialCustomization = {
    color: undefined,
    purity: undefined,
    remarks: undefined,
    size: undefined,
    screw: undefined,
    setting: undefined,
    grossWeight: undefined,
    specificEngraving: undefined,
    diamondDetails: undefined,

    jewelPrice: undefined,
    jewelPriceBreakup: undefined,
  };

const ProductCard = ({
  product,
  puritiesMaster,
  cartItem,
  masterData,
  // products = [],
  paginationInfo,
  isMobile,
  // prodIndex,
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const [imageError, setImageError] = useState(false);
  const [renderedImage, setRenderImage] = useState({
    src: product?.defaultImage?.signedUrl
      ? getCompressedImageUrl(product?.defaultImage?.signedUrl)
      : oopsNotFound,
  });

  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [openImageOnly, setOpenImageOnly] = useState(false);
  const [customization, setCustomization] = useState(initialCustomization);

  const {
    handleAddToCart: onAddToCart,
    handleRemoveFromCart: onRemoveFromCart,
  } = useCartActions();

  // Extract clarity and color options from commonEnums
  const componentStyles = {
    label: {
      fontFamily: "Lato",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "14.4px",
      textAlign: "left",
      textUnderlinePosition: "from-font",
      textDecorationSkipInk: "none",
      color: "grey",
    },
    value: {
      fontFamily: "Lato",
      // fontSize: "14px",
      fontSize: "14px",
      color: enums.colorTheme.secondary,
      fontWeight: 500,
      lineHeight: "14.4px",
      textAlign: "left",
      textUnderlinePosition: "from-font",
      textDecorationSkipInk: "none",
      wordBreak: "break-all",
    },
    renderOption: {
      fontSize: "12px",
      padding: "4px 8px",
      borderRadius: "4px",
      color: "#000",
      width: "100%",
      boxSizing: "border-box",
      // textAlign: "center",
      // alignItems: "center",
      // justifyContent: "center",
    },

    ellipsisStyle: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "150px",
    },
  };

  const textFieldSx = {
    "& .MuiInputBase-root": {
      padding: "0 4px",
      lineHeight: "1.2",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "24px",
      color: enums.colorTheme.secondary,
      "@media (max-width: 600px)": {
        justifyContent: "space-between",
        height: "32px",
        // padding: "0 8px",
      },
    },
    "& .MuiInputBase-input": {
      paddingLeft: "-8px",
      margin: "auto 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: isMobile ? "50%" : "80%",
      textAlign: "left",
      fontSize: "12px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "@media (max-width: 600px)": {
        whiteSpace: "normal",
        overflow: "visible",
        textOverflow: "unset",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: `${enums.colorTheme.secondaryLight}`,
      },
      "&:hover fieldset": {
        borderColor: `${enums.colorTheme.secondaryLight}`,
      },
      "&.Mui-focused fieldset": {
        borderColor: `${enums.colorTheme.secondaryLight}`,
      },
      "@media (max-width: 600px)": {
        "& fieldset": {
          borderWidth: "1px",
        },
      },
    },
    "& .MuiInput-underline:before": {
      borderBottom: `2px solid ${enums.colorTheme.secondaryLight} !important`, // Restore underline color
      width: isMobile ? "60%" : "80%", // Make underline span full width of the container
    },
    "& .MuiInput-underline:hover:before": {
      borderBottom: `2px solid ${enums.colorTheme.secondaryLight} !important`, // Add hover effect for underline
      width: isMobile ? "60%" : "80%", // Ensure full width for hover
    },
    "& .MuiInput-underline:after": {
      borderBottom: `2px solid ${enums.colorTheme.secondaryLight} !important`, // Focused underline color
      width: isMobile ? "60%" : "80%", // Ensure full width for focus
    },
  };

  const autoCompleteSx = {
    "& .MuiAutocomplete-inputRoot": {
      padding: "0",
      display: "flex",
      alignItems: "center",
      height: "24px",
      color: enums.colorTheme.secondary,
      "& fieldset": {
        borderColor: enums.colorTheme.secondaryLight, // Default border color
      },
      "&:hover fieldset": {
        borderColor: enums.colorTheme.secondaryLight, // Hover border color
      },
      "&.Mui-focused fieldset": {
        borderColor: enums.colorTheme.secondaryLight, // Focus border color
      },
      "@media (max-width: 600px)": {
        height: "32px", // Increase height for better usability
        padding: "0 8px", // Add padding to match mobile spacing
      },
    },
    "& .MuiAutocomplete-input": {
      textAlign: "center",
      whiteSpace: "nowrap", // Prevent wrapping text
      overflow: "hidden", // Hide overflow for long text
      textOverflow: "ellipsis", // Show ellipsis for overflowing text
      "@media (max-width: 600px)": {
        textAlign: "left", // Left-align text for readability
        whiteSpace: "normal", // Allow wrapping for long text
        overflow: "visible", // Ensure full visibility
        textOverflow: "unset", // Disable ellipsis
      },
    },
    "& .MuiAutocomplete-listbox": {
      maxHeight: "250px", // Restrict dropdown height
      overflowY: "auto", // Enable scrolling if list exceeds height
      "& li": {
        width: "100%",
        backgroundColor: "transparent",
        padding: "4px 8px", // Adjust padding for better spacing
        fontSize: "14px", // Match font size to input
        "&:hover": {
          backgroundColor: enums.colorTheme.secondaryLight,
        },
      },
      "@media (max-width: 600px)": {
        maxHeight: "200px", // Reduce height for smaller screens
        fontSize: "12px", // Adjust font size for mobile
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      position: "absolute",
      right: isMobile ? "36%" : "20%",
      top: "50%",
      transform: "translateY(-50%)",
    },
  };

  const editableFieldSx = {
    display: "grid", // Use grid for alignment
    gridTemplateColumns: "1fr 3fr", // Define columns: 1 part for label, 3 parts for value
    alignItems: "center", // Align items vertically in the center
    columnGap: 2, // Add spacing between label and value
    rowGap: 1, // Add spacing between rows
    width: isMobile ? "140px" : undefined,
    maxWidth: isMobile ? "140px" : undefined,
  };

  const setQuantityState = (updatedCustomization) => {
    const cartItems = store.getState().cart?.items || [];

    const existingIndex = cartItems.findIndex((item) => {
      return (
        item?.product?._id === product?._id &&
        (item.isCustomized
          ? isCustomizationMatch(item, updatedCustomization || customization)
          : updatedCustomization
          ? !isCustomizationExist(updatedCustomization)
          : !isCustomizationExist(customization))
      );
    });

    if (
      existingIndex > -1
      //  && quantity !== cartItems[existingIndex].quantity
    ) {
      setQuantity(cartItems[existingIndex].quantity);
    } else {
      // quantity !== cartItems[existingIndex]?.quantity &&
      // quantity !== initialQuantity
      setQuantity(initialQuantity);
    }
  };

  useEffect(() => {
    if (cartItem) {
      setCustomization({
        color: cartItem.color,
        remarks: cartItem.remarks,
        purity: cartItem.purity,
        size: cartItem.size,
        screw: cartItem.screw,
        setting: cartItem.setting,
        grossWeight: cartItem.grossWeight,
        specificEngraving: cartItem.specificEngraving,
        diamondDetails: cartItem.diamondDetails,
        //
        jewelPrice: cartItem.jewelPrice,
        jewelPriceBreakup: cartItem.jewelPriceBreakup,
      });
    }
    if (cartItem) {
      setQuantity(cartItem.quantity);
    } else {
      setQuantityState();
    }
  }, [cartItem]);

  const getCustomizedPrice = (purity) => {
    try {
      const { jewelPrice, jewelPriceBreakup } =
        attachJewelPrice(
          [
            {
              ...product,
              purity: purity, // customization.purity || product.purity,
            },
          ],
          userDetails._id,
          {
            ...masterData,
            globalGoldRatePerGram: paginationInfo?.liveGoldPrice,
          }
        )?.[0] || {};

      return { jewelPrice: jewelPrice, jewelPriceBreakup: jewelPriceBreakup };
    } catch (err) {
      console.error(err);
      return { jewelPrice: "-", jewelPriceBreakup: {} };
    }
  };

  const handleCustomizationChange = (key, value) => {
    const updatedCustomization = { ...customization };

    switch (key) {
      case "purity":
        updatedCustomization.purity =
          product.purity.percent === value.percent ? undefined : value;

        const isCustomized = product.purity.percent !== value.percent;

        if (isCustomized) {
          const priceValue = getCustomizedPrice(updatedCustomization.purity);

          updatedCustomization.jewelPrice = priceValue.jewelPrice;
          updatedCustomization.jewelPriceBreakup = priceValue.jewelPriceBreakup;
        } else {
          updatedCustomization.jewelPrice = undefined;
          updatedCustomization.jewelPriceBreakup = undefined;
        }

        break;
      case "clarity":
        if (product.diamondDetails?.length) {
          value =
            product.diamondDetails?.[0]?.clarity === value ? undefined : value; // only if first diamond clarity is customized, diamonds are considered to be customized... #31_01_2025

          updatedCustomization.diamondDetails = product.diamondDetails.map(
            (_, diaIndex) => ({
              ...customization.diamondDetails?.[diaIndex],
              clarity:
                product.diamondDetails[diaIndex].clarity === value
                  ? undefined
                  : value,
            })
          );
        }

        break;

      case "dColor":
        if (product.diamondDetails?.length) {
          value =
            product.diamondDetails?.[0]?.color === value ? undefined : value; // only if first diamond color is customized, diamonds are considered to be customized... #31_01_2025
          updatedCustomization.diamondDetails = product.diamondDetails.map(
            (_, diaIndex) => ({
              ...customization.diamondDetails?.[diaIndex],
              color:
                product.diamondDetails[diaIndex].color === value
                  ? undefined
                  : value,
            })
          );
        }

        break;

      case "remarks":
        updatedCustomization[key] = value ? value : undefined;
        break;

      default:
        // null case only handled for size field as all other options donot have disableClearable to clear inputs
        value = value.toString().trim() ? value : null;
        updatedCustomization[key] =
          product[key] == value ? undefined : value ? value : null;
    }

    setCustomization(updatedCustomization);
    setQuantityState(updatedCustomization);
  };

  const handleImageError = () => {
    setImageError(true);
  };
  // const handleImageError = async () => {
  //   try {
  //     if (product.imageFlag) {
  //       setRenderImage({
  //         src: loadingGif,
  //       });
  //       const res = await signStoredObjects(
  //         commonEnums.models["data-engine"].products,
  //         { codes: [product.code] }
  //       );

  //       setRenderImage({
  //         src: res[0]?.defaultImage?.signedUrl
  //           ? getCompressedImageUrl(res[0]?.defaultImage?.signedUrl)
  //           : oopsNotFound,
  //       });
  //     } else {
  //       setImageError(true);
  //     }
  //   } catch (err) {
  //     setImageError(true);
  //   }
  // };

  const handleAddToCart = (updateQuantity = false) => {
    if (quantity > 999) {
      return Emitter.emit("alert_error", "Maximum Quantity Allowed: 999");
    }
    if (quantity > 0) {
      onAddToCart(
        {
          ...customization,
          quantity: updateQuantity // now updateQuantity wont be false for this function used. its separate in handleAddButtonClick
            ? quantity
            : 1,
          product,
        },
        updateQuantity
      );
    } else {
      onRemoveFromCart({ ...customization, product });
    }

    // setCustomization({});
    // setQuantity(
    //   updateQuantity // now updateQuantity wont be false for this function used. its separate in handleAddButtonClick
    //     ? quantity
    //     : 1
    // );
    // Emitter.emit("alert_success", "Added to Cart");

    setShowInput(false);
  };

  const handleAddButtonClick = () => {
    if (Number(quantity) < 5) {
      if (quantity < 999) {
        setQuantity((quantity || 0) + 1);
        // handleAddToCart();
        onAddToCart({
          ...customization,
          quantity: 1,
          product,
        });
      }
    } else {
      setShowInput(true);
    }
  };

  const handleRemoveFromCart = () => {
    if (quantity > 0) {
      if (quantity > 6) {
        setShowInput(true);
      } else {
        setQuantity(quantity - 1);
        onRemoveFromCart({ ...customization, product }, { quantityOnly: true });
      }
    }
  };

  // const handleRemoveButtonClick = () => {
  //   if (quantity > 1) {
  //     setQuantity((quantity || 0) - 1);
  //   }
  // };

  return (
    <Card
      style={{
        margin: "2.5px",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        // padding: "4px",
        borderRadius: "5px",
        border: "1px solid #D0F0FF",
        overflow: "hidden",
        userSelect: "none",
        height: "420px",
      }}
    >
      {openDetailDialog && (
        <MyDetailDrawer
          open={openDetailDialog}
          onClose={() => setOpenDetailDialog(false)}
          product={product}
        />
      )}

      {openImageOnly && (
        <FullscreenPreview
          open={openImageOnly}
          onClose={() => setOpenImageOnly(false)}
          imageSrc={product?.defaultImage?.signedUrl || oopsNotFound}
        />
      )}

      <Grid container style={{ padding: "8px" }}>
        {/* Left Side: Product Image */}
        <Grid item xs={7} container>
          <Grid item xs={12}>
            <LazyLoad
              fallback={
                <div
                  style={{ height: "268px", backgroundColor: "#f0f0f0" }}
                ></div>
              }
              // preloadNext={() => {
              //   debugger;
              //   const nextBatch = products.slice(prodIndex + 1, prodIndex + 11); // Preload next 10
              //   nextBatch.forEach((nextProduct) => {
              //     if (nextProduct?.defaultImage?.signedUrl) {
              //       const img = new Image();
              //       img.src = getCompressedImageUrl(
              //         nextProduct?.defaultImage?.signedUrl
              //       );
              //     }
              //   });
              // }}
            >
              <CardMedia
                onClick={() => setOpenImageOnly(true)}
                component="img"
                style={{
                  objectFit: "contain",
                  paddingRight: "8px",
                  maxWidth: "268px",
                  minHeight: "268px",
                  maxHeight: "300px",
                  flexGrow: 1, // Ensures it grows with the card height
                  borderRadius: "5px",
                  transition: "border 2s ease",
                  // ...renderedImage.style,
                }}
                image={
                  imageError
                    ? imageExpired
                    : product?.defaultImage?.signedUrl
                    ? getCompressedImageUrl(product?.defaultImage?.signedUrl)
                    : oopsNotFound
                }
                onError={handleImageError}
                alt={product?.styleNo}
                onContextMenu={(e) => e.preventDefault()} // Prevent right-click download option
              />
            </LazyLoad>
          </Grid>

          <Grid item xs={12} container style={{ margin: "4px" }}>
            <Grid item lg={6} xs={6}>
              {cartItem ? (
                <>
                  <span style={{ ...componentStyles.label, color: "grey" }}>
                    Color:{" "}
                  </span>
                  <span
                    style={{
                      ...componentStyles.value,
                      background:
                        {
                          R: "#FFCCCC",
                          W: "#E0E0E0",
                          WR: "linear-gradient(to right, #E0E0E0, #FFCCCC)",
                          Y: "#FFFF99",
                          YR: "linear-gradient(to right, #FFFF99, #FFCCCC)",
                          YW: "linear-gradient(to right, #FFFF99, #E0E0E0)",
                          YRW: "linear-gradient(to right, #FFFF99, #FFCCCC, #E0E0E0)",
                        }[customization.color || product?.color] ||
                        "transparent",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      color: enums.colorTheme.secondary,
                      display: "inline-block",
                      textAlign: "center",
                      minWidth: isMobile ? "30px" : "50px",
                    }}
                  >
                    {customization.color || product?.color}
                  </span>
                </>
              ) : (
                <Box style={editableFieldSx}>
                  <span style={{ ...componentStyles.label }}>Color: </span>
                  <span>
                    <Autocomplete
                      PopperComponent={(props) => (
                        <Popper
                          {...props}
                          sx={{ zIndex: 1300 }}
                          placement="bottom-start"
                        />
                      )}
                      options={Object.values(commonEnums.colorOptions)}
                      fullWidth
                      size="small"
                      filterOptions={(x) => x}
                      onChange={(_, value) => {
                        handleCustomizationChange("color", value);
                        setTimeout(() => {
                          document.activeElement.blur(); // Ensures the input field is blurred after selection
                        }, 0);
                      }}
                      disableClearable
                      value={customization.color || product?.color}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          size="small"
                          variant="standard"
                          fullWidth
                          sx={textFieldSx}
                          inputProps={{
                            ...props.inputProps,
                            readOnly: true, // Prevent keyboard from opening
                          }}
                        />
                      )}
                      renderOption={(props, option) => {
                        // const backgroundColors = {
                        //   R: "#FFCCCC",
                        //   W: "#E0E0E0",
                        //   WR: "linear-gradient(to right, #E0E0E0, #FFCCCC)",
                        //   Y: "#FFFF99",
                        //   YR: "linear-gradient(to right, #FFFF99, #FFCCCC)",
                        //   YW: "linear-gradient(to right, #FFFF99, #E0E0E0)",
                        //   YRW: "linear-gradient(to right, #FFFF99, #FFCCCC, #E0E0E0)",
                        // };

                        return (
                          <li {...props} style={componentStyles.renderOption}>
                            {option}
                          </li>
                        );
                      }}
                      sx={autoCompleteSx}
                    />
                  </span>
                </Box>
              )}
            </Grid>

            <Grid
              style={{ paddingLeft: isMobile ? "8px" : undefined }}
              item
              lg={6}
              xs={6}
            >
              {cartItem ? (
                <>
                  <span style={{ ...componentStyles.label, color: "grey" }}>
                    Purity:{" "}
                  </span>
                  <span style={componentStyles.value}>
                    {customization.purity?.percent
                      ? (customization.purity?.percent * 100).toFixed(2) + " %"
                      : (product.purity?.percent * 100).toFixed(2) + " %"}
                  </span>
                </>
              ) : (
                <Box style={editableFieldSx}>
                  <span style={{ ...componentStyles.label }}>Purity: </span>
                  <span>
                    <Autocomplete
                      PopperComponent={(props) => (
                        <Popper
                          {...props}
                          sx={{ zIndex: 1300 }}
                          placement="bottom-start"
                        />
                      )}
                      size="small"
                      fullWidth
                      filterOptions={(x) => x}
                      disableClearable
                      options={Array.from(
                        new Map(
                          (puritiesMaster || [])
                            .filter((purity) =>
                              purity.division?._id
                                ? purity.division?._id === product.division._id
                                : true
                            )
                            .map((item) => [item.percent, item])
                        ).values()
                      )}
                      getOptionLabel={(op) =>
                        op?.percent ? `${(op.percent * 100).toFixed(2)} %` : ""
                      }
                      value={customization.purity || product?.purity}
                      onChange={(_, value) => {
                        handleCustomizationChange("purity", value);
                        // event.target.blur && event.target.blur();/
                        setTimeout(() => {
                          document.activeElement.blur(); // Ensures the input field is blurred after selection
                        }, 0);
                      }}
                      getOptionSelected={(opt, val) => opt?._id === val?._id}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          fullWidth
                          size="small"
                          variant="standard"
                          sx={textFieldSx}
                          inputProps={{
                            ...props.inputProps,
                            readOnly: true, // Prevent keyboard from opening
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} style={componentStyles.renderOption}>
                          {option?.percent
                            ? `${(option.percent * 100).toFixed(2)} %`
                            : ""}
                        </li>
                      )}
                      sx={autoCompleteSx}
                    />
                  </span>
                </Box>
              )}
            </Grid>

            {product.diamondDetails?.[0]?.clarity ||
            product.diamondDetails?.[0]?.color ? (
              <>
                <Grid item lg={6} xs={6}>
                  {cartItem ? (
                    <>
                      <span style={{ ...componentStyles.label, color: "grey" }}>
                        Dclarity:{" "}
                      </span>
                      <span
                        style={{
                          ...componentStyles.value,
                          textAlign: "center",
                        }}
                      >
                        {customization.diamondDetails?.[0]?.clarity ||
                          product.diamondDetails?.[0]?.clarity ||
                          ""}
                      </span>
                    </>
                  ) : (
                    <Box style={editableFieldSx}>
                      <span style={{ ...componentStyles.label }}>Dclty: </span>

                      <span>
                        <Autocomplete
                          PopperComponent={(props) => (
                            <Popper
                              {...props}
                              sx={{ zIndex: 1300 }}
                              placement="bottom-start"
                            />
                          )}
                          getOptionSelected={(option, value) =>
                            option === value
                          } // Check if the option matches the selected value
                          options={Object.values(
                            commonEnums.diamondMasterData.clarity
                          )}
                          fullWidth
                          size="small"
                          filterOptions={(x) => x} // Keep default filtering
                          onChange={(_, value) => {
                            handleCustomizationChange("clarity", value);
                            setTimeout(() => {
                              document.activeElement.blur(); // Ensures the input field is blurred after selection
                            }, 0);
                          }}
                          disableClearable
                          value={
                            customization.diamondDetails?.[0]?.clarity ||
                            product.diamondDetails?.[0]?.clarity ||
                            ""
                          }
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              size="small"
                              variant="standard"
                              fullWidth
                              sx={textFieldSx}
                              inputProps={{
                                ...props.inputProps,
                                readOnly: true, // Prevent keyboard from opening
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} style={componentStyles.renderOption}>
                              {option} {/* Display the option's name */}
                            </li>
                          )}
                          sx={autoCompleteSx}
                        />
                      </span>
                    </Box>
                  )}
                </Grid>

                <Grid
                  style={{ paddingLeft: isMobile ? "8px" : undefined }}
                  item
                  lg={6}
                  xs={6}
                >
                  {cartItem ? (
                    <>
                      <span style={{ ...componentStyles.label, color: "grey" }}>
                        Dcolor:{" "}
                      </span>
                      <span style={componentStyles.value}>
                        {customization.diamondDetails?.[0]?.color ||
                          product.diamondDetails?.[0]?.color ||
                          ""}
                      </span>
                    </>
                  ) : (
                    <Box style={editableFieldSx}>
                      <span style={{ ...componentStyles.label }}>Dcolor: </span>

                      <span>
                        <Autocomplete
                          PopperComponent={(props) => (
                            <Popper
                              {...props}
                              sx={{ zIndex: 1300 }}
                              placement="bottom-start"
                            />
                          )}
                          getOptionSelected={(option, value) =>
                            option === value
                          } // Check if the option matches the selected value
                          options={[
                            ...Object.values(
                              commonEnums.diamondColorOptions.isSolitaire
                            ),
                            ...Object.values(
                              commonEnums.diamondColorOptions.nonSolitaire
                            ),
                          ]}
                          fullWidth
                          size="small"
                          filterOptions={(x) => x} // Keep default filtering
                          onChange={(_, value) => {
                            handleCustomizationChange("dColor", value);
                            setTimeout(() => {
                              document.activeElement.blur(); // Ensures the input field is blurred after selection
                            }, 0);
                          }}
                          disableClearable
                          value={
                            customization.diamondDetails?.[0]?.color ||
                            product.diamondDetails?.[0]?.color ||
                            ""
                          }
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              size="small"
                              variant="standard"
                              fullWidth
                              sx={textFieldSx}
                              inputProps={{
                                ...props.inputProps,
                                readOnly: true, // Prevent keyboard from opening
                              }}
                            />
                          )}
                          openOnFocus
                          renderOption={(props, option) => (
                            <li {...props} style={componentStyles.renderOption}>
                              {option} {/* Display the option's name */}
                            </li>
                          )}
                          sx={autoCompleteSx}
                        />
                      </span>
                    </Box>
                  )}
                </Grid>
              </>
            ) : null}

            <Grid item lg={6} xs={6}>
              {cartItem ? (
                <>
                  <span style={{ ...componentStyles.label, color: "grey" }}>
                    Size:{" "}
                  </span>
                  <span style={componentStyles.value}>
                    {customization.size === null || product.size === null
                      ? ""
                      : (customization.size !== undefined
                          ? "" + customization.size
                          : "" + product.size) || ""}
                  </span>
                </>
              ) : (
                <Box style={editableFieldSx}>
                  <span style={{ ...componentStyles.label }}>Size: </span>
                  <span>
                    <TextField
                      variant="standard"
                      value={
                        customization.size === undefined
                          ? product.size
                          : customization.size
                      }
                      // defaultValue={ || ""}
                      onChange={(e) =>
                        handleCustomizationChange("size", e.target.value)
                      }
                      type="number"
                      sx={textFieldSx}
                      fullWidth
                      size="small"
                    />
                  </span>
                </Box>
              )}
            </Grid>

            {product?.screw ? (
              <Grid
                style={{ paddingLeft: isMobile ? "8px" : undefined }}
                item
                lg={6}
                xs={6}
              >
                {cartItem ? (
                  <>
                    <span style={{ ...componentStyles.label, color: "grey" }}>
                      Screw:{" "}
                    </span>
                    <span style={componentStyles.value}>
                      {customization.screw || product?.screw}
                    </span>
                  </>
                ) : (
                  <Box style={editableFieldSx}>
                    <span style={{ ...componentStyles.label }}>Screw: </span>
                    <span>
                      <Autocomplete
                        PopperComponent={(props) => (
                          <Popper
                            {...props}
                            sx={{ zIndex: 1300 }}
                            placement="bottom-start"
                          />
                        )}
                        options={Object.values(commonEnums.screws)}
                        fullWidth
                        size="small"
                        filterOptions={(x) => x}
                        onChange={(_, value) => {
                          handleCustomizationChange("screw", value);
                          setTimeout(() => {
                            document.activeElement.blur(); // Ensures the input field is blurred after selection
                          }, 0);
                        }}
                        disableClearable
                        value={customization.screw || product?.screw}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            size="small"
                            variant="standard"
                            fullWidth
                            sx={textFieldSx}
                            inputProps={{
                              ...props.inputProps,
                              readOnly: true, // Prevent keyboard from opening
                            }}
                          />
                        )}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} style={componentStyles.renderOption}>
                              {option}
                            </li>
                          );
                        }}
                        sx={autoCompleteSx}
                      />
                    </span>
                  </Box>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        {/* Right Side: Product Details */}
        <Grid item xs={5} container style={{ paddingLeft: "8px" }}>
          {/* Price Stats Container */}
          <Grid item xs={12} style={{ padding: cartItem ? "4px" : 0 }}>
            <Details
              isMobile={isMobile}
              product={product}
              setOpenDetailDialog={setOpenDetailDialog}
              componentStyles={componentStyles}
            />
          </Grid>

          {/* #remarks */}
          <Grid item xs={12}>
            {cartItem ? (
              <div
                onClick={() =>
                  customization.remarks?.toString().trim() &&
                  alert(customization.remarks)
                }
              >
                <Label
                  labelStyle={{
                    color: "grey",
                    fontSize: "12px",
                  }}
                  label={"Remarks"}
                  value={
                    <ReadMore
                      style={{ paddingTop: "-8px" }}
                      readMoreStyle={{ fontSize: "6px" }}
                      contentStyle={{
                        ...componentStyles.value,
                        color: enums.colorTheme.secondary,
                        fontSize: "12px",
                      }}
                      toggleReadMore={false}
                      content={customization.remarks?.replace(/\n/g, " ") || ""}
                      limit={50}
                    />
                  }
                />
              </div>
            ) : (
              <Box
              // style={{ margin: "auto" }}
              >
                <TextField
                  variant="outlined"
                  label={"Remarks"}
                  value={customization.remarks || ""}
                  onChange={(e) =>
                    handleCustomizationChange("remarks", e.target.value)
                  }
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true, // Ensures the label stays in the right position
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      fontSize: "12px",
                      "& fieldset": {
                        borderColor: enums.colorTheme.secondaryLight,
                      },
                      "&:hover fieldset": {
                        borderColor: enums.colorTheme.primary,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: enums.colorTheme.primary,
                      },
                    },
                    "& .MuiInputBase-input": {
                      margin: 0, // Ensure input text aligns properly
                      padding: "8px", // Consistent padding for text
                      height: "48px",
                    },
                    "& .MuiInputLabel-root": {
                      ...componentStyles.label,
                    },
                  }}
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  style={{ ...componentStyles.value }} // Inline styles for additional customizations
                />
              </Box>
            )}
          </Grid>

          {/* Quantity Container */}
          <Grid item container xs={12} style={{ marginLeft: "16px" }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {/* Price */}
              {product?.jewelPrice || customization.jewelPrice ? (
                <JewelPriceDisplay
                  customization={customization}
                  isMobile={isMobile}
                  product={product}
                />
              ) : null}
            </Grid>

            {/* <Grid item xs={12} style={{ textAlign: "center" }}>
              {cartItem ? (
                
              ) : (
               
              )}
            </Grid> */}

            {/* Quantity Control and Remarks */}

            {cartItem ? null : (
              <Grid item xs={12}>
                <DisplayCartCount product={product} />
              </Grid>
            )}
            <Grid item xs={12}>
              {quantity > 0 && !showInput ? (
                <Box
                  className="quantity-control"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "auto",
                  }}
                >
                  <IconButton
                    onClick={handleRemoveFromCart}
                    onMouseEnter={(event) => {
                      event.currentTarget.style.backgroundColor = "#075985"; // Hover background color
                      event.currentTarget.style.color = "#EFF6FF"; // Hover text color
                    }}
                    onMouseLeave={(event) => {
                      event.currentTarget.style.backgroundColor = "#EFF6FF"; // Original background color
                      event.currentTarget.style.color = "#075985"; // Original text color
                    }}
                    onMouseDown={(event) => {
                      event.currentTarget.style.backgroundColor = "#034B72"; // Active background color when clicked
                      event.currentTarget.style.color = "#075985"; // Original text color
                    }}
                    onMouseUp={(event) => {
                      event.currentTarget.style.backgroundColor = "#075985"; // Back to hover color after click
                      event.currentTarget.style.color = "#EFF6FF"; // Hover text color
                    }}
                    style={{
                      backgroundColor: "#EFF6FF", // Initial background color
                      color: "#075985", // Initial text color
                      borderRadius: "50%",
                      height: "32px",
                      width: "32px",
                      userSelect: "none", // Prevent text selection
                      transition: "background-color 0.3s, color 0.3s", // Smooth transitions
                    }}
                  >
                    <Remove />
                  </IconButton>

                  <span style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    {quantity}
                  </span>
                  <IconButton
                    onClick={handleAddButtonClick}
                    onMouseEnter={(event) => {
                      event.currentTarget.style.backgroundColor = "#075985"; // Hover background color
                      event.currentTarget.style.color = "#EFF6FF"; // Hover text color
                    }}
                    onMouseLeave={(event) => {
                      event.currentTarget.style.backgroundColor = "#EFF6FF"; // Original background color
                      event.currentTarget.style.color = "#075985"; // Original text color
                    }}
                    onMouseDown={(event) => {
                      event.currentTarget.style.backgroundColor = "#034B72"; // Active background color when clicked
                      event.currentTarget.style.color = "#075985"; // Original text color
                    }}
                    onMouseUp={(event) => {
                      event.currentTarget.style.backgroundColor = "#075985"; // Back to hover color after click
                      event.currentTarget.style.color = "#EFF6FF"; // Hover text color
                    }}
                    style={{
                      backgroundColor: "#EFF6FF", // Initial background color
                      color: "#075985", // Initial text color
                      borderRadius: "50%",
                      userSelect: "none", // Prevent text selection
                      transition: "background-color 0.3s, color 0.3s", // Smooth transitions
                      height: "32px",
                      width: "32px",
                      fontWeight: 10,
                    }}
                  >
                    <Add />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  className="quantity-control"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "auto",
                  }}
                >
                  {showInput ? (
                    <TextField
                      value={quantity || ""}
                      onChange={(e) => handleQuantityChange(e, setQuantity)}
                      onKeyDown={(e) =>
                        e.key === "Enter" && handleAddToCart(true)
                      }
                      type="number"
                      autoComplete="tel"
                      variant="outlined"
                      autoFocus={true}
                      style={{
                        width: "100%",
                        // maxHeight: "20px",
                      }}
                      InputProps={{
                        style: {
                          display: "flex",
                          padding: "8px",
                        },
                        inputProps: {
                          style: {
                            fontSize: "12px",
                          },
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                handleAddToCart(true);
                              }}
                              onMouseEnter={(event) => {
                                event.currentTarget.style.backgroundColor =
                                  "#075985"; // Hover background color
                                event.currentTarget.style.color = "#EFF6FF"; // Hover text color
                              }}
                              onMouseLeave={(event) => {
                                event.currentTarget.style.backgroundColor =
                                  "#EFF6FF"; // Original background color
                                event.currentTarget.style.color = "#075985"; // Original text color
                              }}
                              onMouseDown={(event) => {
                                event.currentTarget.style.backgroundColor =
                                  "#034B72"; // Active background color when clicked
                                event.currentTarget.style.color = "#075985"; // Original text color
                              }}
                              onMouseUp={(event) => {
                                event.currentTarget.style.backgroundColor =
                                  "#075985"; // Back to hover color after click
                                event.currentTarget.style.color = "#EFF6FF"; // Hover text color
                              }}
                              style={{
                                // marginTop: "-12px",
                                backgroundColor: "#EFF6FF",
                                color: "#075985",
                                borderRadius: "50%",
                                height: "32px",
                                width: "32px",
                              }}
                            >
                              <CheckIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Button
                      // variant="contained"
                      // className="add-to-cart-button"
                      onClick={handleAddButtonClick}
                      style={{
                        padding: "8px 16px",
                        marginTop: "auto",
                        width: "100%",
                        textTransform: "none",
                        fontSize: "1rem",
                        // fontWeight: "bold",
                        background: "#EFF6FF",
                        color: "#075985",
                        borderRadius: "5px",
                      }}
                      onMouseEnter={(event) => {
                        event.target.style.backgroundColor = "#075985"; // Swap background color on hover
                        event.target.style.color = "#EFF6FF";
                      }}
                      onMouseLeave={(event) => {
                        event.target.style.backgroundColor = "#EFF6FF"; // Original background color
                        event.target.style.color = "#075985";
                      }}
                    >
                      {isMobile ? "Add" : "Add to Cart"}
                    </Button>
                  )}
                </Box>
              )}

              <center>
                {cartItem ? (
                  <MyHoverIcons
                    tooltip={"remove"}
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure to remove ${product.styleNo} with ${quantity} quantity?`
                        )
                      ) {
                        onRemoveFromCart({ ...customization, product });
                      }
                    }}
                    style={{ paddingTop: "24px" }}
                    muiIcon={<DeleteOutlinedIcon />}
                    muiIconHover={<DeleteSweepIcon />}
                  />
                ) : isCustomizationExist(customization) ? (
                  <pre
                    style={{
                      fontSize: "12px",
                      color: "red",
                      paddingTop: "8px",
                      fontStyle: "italic",
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.textDecoration = "underline")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.textDecoration = "none")
                    }
                    onClick={() => {
                      if (window.confirm("Reset Customization: confirm?")) {
                        setCustomization(initialCustomization);
                        setQuantityState(initialCustomization);
                      }
                    }}
                  >
                    reset
                  </pre>
                ) : null}
              </center>
            </Grid>
            {/* <Grid item xs={12}>
              <Button
                disabled={cartItem}
                onClick={handleAddToCart}
                style={{
                  padding: "8px 16px",
                  marginRight: "8px",
                  width: "100%",
                  textTransform: "none",
                  fontSize: isMobile ? "10px" : "12px",
                  fontWeight: "bold",
                  background: "#EFF6FF",
                  color: "#075985",
                  height: "32px",
                  border: "1px solid #86D2F4",
                  borderRadius: "5px",
                }}
                onMouseEnter={(event) => {
                  event.target.style.backgroundColor = "#075985"; // Swap background color on hover
                  event.target.style.color = "#EFF6FF";
                }}
                onMouseLeave={(event) => {
                  event.target.style.backgroundColor = "#EFF6FF"; // Original background color
                  event.target.style.color = "#075985";
                }}
              >
                {cartItem ? "Qty: " + quantity : isMobile ? "Add" : "Add"}
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

const JewelPriceDisplay = ({ product, customization, isMobile }) => {
  const [displayPrice, setDisplayPrice] = useState(""); // Current displayed price
  const jewelPrice = customization.jewelPrice || product?.jewelPrice || 0;

  useEffect(() => {
    const formattedPrice = `₹ ${
      isNaN(jewelPrice) ? "-" : formatIndianNumber(roundOff(jewelPrice))
    }`;
    let index = 0;

    // Reset displayPrice before starting the new animation
    setDisplayPrice("");

    // Animate the display of the price
    const interval = setInterval(() => {
      setDisplayPrice((prev) => prev + formattedPrice[index]);
      index++;
      if (index === formattedPrice.length) clearInterval(interval); // Stop animation
    }, 50); // Delay between each letter

    return () => clearInterval(interval); // Cleanup on price change
  }, [jewelPrice]);

  return (
    <Typography
      variant="h6"
      style={{
        color: "#2E8645",
        fontWeight: 500,
        fontSize: isMobile ? "12px" : "16px",
        textAlign: "center",
      }}
    >
      {displayPrice.split("").map((char, index) => (
        <span
          key={index}
          className="letter"
          style={{ "--delay": `${index * 50}ms` }} // Dynamic delay for each letter
        >
          {char}
        </span>
      ))}
    </Typography>
  );
};

const Details = ({
  product,
  setOpenDetailDialog,
  isMobile,
  componentStyles,
}) => {
  const designLimit = isMobile ? 8 : 10;
  const typographySex = {
    display: "grid",
    gridTemplateColumns: "35% 65%", // Equal width for label and value
    gap: "4px", // Reduced spacing for a tighter layout
    alignItems: "center", // Align items vertically in the center
    width: "100%",
  };

  const boxSx = {
    width: "100%",
    padding: "12px",
    borderRadius: "5px",
    background: "linear-gradient(180deg, #F0F9FF 0%, #FFFFFF 100%)",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
    },
    height: "100px",
    gap: "8px", // Consistent gap between items in the box
  };
  return (
    <Box sx={boxSx} onClick={() => setOpenDetailDialog(true)}>
      {/* Style Number */}
      <Typography variant="h6" sx={typographySex}>
        <span style={componentStyles.label}>Design:</span>
        <Tooltip title={product?.styleNo || ""} arrow>
          <span
            style={{
              ...componentStyles.value,
              wordBreak: "keep-all",
              whiteSpace: "nowrap",
            }}
          >
            {product?.styleNo?.length > designLimit
              ? `${product?.styleNo.substring(0, designLimit)}...`
              : product?.styleNo}
          </span>
        </Tooltip>
      </Typography>

      {/* Gross Weight */}
      <Typography variant="body2" color="textSecondary" sx={typographySex}>
        <span style={componentStyles.label}>Gross:</span>
        <span style={componentStyles.value}>
          {formatWeight(product?.grossWeight)}
        </span>
      </Typography>

      {/* Diamond Weight */}
      {product?.totalDiamondWeight ? (
        <Typography variant="body2" color="textSecondary" sx={typographySex}>
          <span style={componentStyles.label}>Dia:</span>
          <span style={componentStyles.value}>
            {formatWeight(product?.totalDiamondWeight, {
              inputInCts: true,
            })}
          </span>
        </Typography>
      ) : null}

      {/* Net Weight */}
      <Typography variant="body2" color="textSecondary" sx={typographySex}>
        <span style={componentStyles.label}>Net:</span>
        <span style={componentStyles.value}>
          {formatWeight(
            product?.jewelPriceBreakup?.appliedNetWeight || product?.netWeight
          )}
        </span>
      </Typography>
    </Box>
  );
};

const DisplayCartCount = ({ product }) => {
  const [count, setCount] = useState({
    //  customizedItems: 0,
    totalQuantity: 0,
  });
  const cartItems = useSelector((state) => state.cart)?.items || [];

  useEffect(() => {
    let tempCount = 0;
    // tempCustomizedItems = 0;
    cartItems.forEach((item) => {
      if (item.product?._id === product?._id) {
        tempCount += item.quantity;
        // tempCustomizedItems++;
      }
    });
    setCount({
      // customizedItems: tempCustomizedItems,
      totalQuantity: tempCount,
    });
  }, [product, cartItems]);

  return count.totalQuantity ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
        color: "grey",
        background: "#f5f5f5",
        padding: "4px 8px",
        borderRadius: "12px",
        width: "100%",
        gap: "24px",
      }}
    >
      {[
        // {
        //   icon: <AccountTreeOutlinedIcon />,
        //   hoverIcon: <AccountTreeIcon />,
        //   value: count.customizedItems,
        // },
        {
          icon: <ShoppingCartOutlinedIcon />,
          hoverIcon: <ShoppingCartIcon />,
          value: count.totalQuantity,
        },
      ].map((item, index) => (
        <span
          key={index}
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
        >
          <MyHoverIcons
            style={{ cursor: "grab" }}
            muiIcon={React.cloneElement(item.icon, {
              style: { fontSize: "14px" },
            })}
            muiIconHover={React.cloneElement(item.hoverIcon, {
              style: { fontSize: "14px" },
            })}
          />
          {item.value}
        </span>
      ))}
    </div>
  ) : null;
};
export default ProductCard;
