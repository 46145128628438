import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { search } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";
import enums from "helpers/enums";

const initialState = []; // Directly store the array of purities

// Async thunk to initialize purities
export const initializePurities = createAsyncThunk(
  "purities/initializePurities",
  async (_, { rejectWithValue }) => {
    try {
      const purities = await search(
        enums.models.purity,
        { filterColumns: { value: 1, percent: 1, division: 1 } },
        { division: "" }
      );

      // Filter unique purities based on value and percent
      // const uniquePurities = Array.from(
      //   purities
      //     .reduce((map, item) => {
      //       const key = `${item.value}-${item.percent}`; // Create a unique key for value and percent
      //       if (!map.has(key)) {
      //         map.set(key, item); // Store unique items in the Map
      //       }
      //       return map;
      //     }, new Map())
      //     .values() // Extract unique values from the Map
      // );

      return purities;
    } catch (error) {
      console.error("Error fetching purities:", error);
      return rejectWithValue(error.message);
    }
  }
);

const puritiesSlice = createSlice({
  name: "purities",
  initialState,
  reducers: {
    clearPurities: () => initialState, // Reset state to an empty array
  },
  extraReducers: (builder) => {
    builder.addCase(initializePurities.fulfilled, (state, action) => {
      return action.payload; // Replace the state with the fetched array
    });
  },
});

export const { clearPurities } = puritiesSlice.actions;
export default puritiesSlice.reducer;
