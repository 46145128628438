import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import commonEnums from "@ui/Utils/commonEnums";

const initialState = []; // Store diamond pricing data

// Async thunk to fetch diamond pricing
export const fetchDiamondPricing = createAsyncThunk(
  "diamonds/fetchDiamondPricing",
  async (_, { rejectWithValue }) => {
    try {
      const diamondPricingList = await search(
        commonEnums.models["data-engine"].diamondPricing
      );
      return diamondPricingList || [];
    } catch (error) {
      console.error("Error fetching diamond pricing:", error);
      return rejectWithValue(error.message);
    }
  }
);

const diamondsSlice = createSlice({
  name: "diamonds",
  initialState,
  reducers: {
    clearDiamonds: () => initialState, // Reset to empty array
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDiamondPricing.fulfilled, (state, action) => {
      return action.payload; // Replace state with fetched diamond pricing data
    });
  },
});

export const { clearDiamonds } = diamondsSlice.actions;
export default diamondsSlice.reducer;
