import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Badge,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import { ShoppingCart, ViewModule, ViewList } from "@mui/icons-material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import "./AppBarComponent.css";
import enums from "helpers/enums";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import WindPowerOutlinedIcon from "@mui/icons-material/WindPowerOutlined";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import {
  //  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import SearchFilter from "./SearchFilter";
// import { search } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";
// import { safeParseJSON } from "@ui/Utils/helper";

export const staticViews = {
  cart: "cart",
  products: "products",
  filterFields: "filter",
  logout: "logout",
};

export const countActiveFilters = (appliedFilterCriteria) => {
  let count = 0;

  if (
    appliedFilterCriteria.grossWeight?.from?.toString().trim() ||
    appliedFilterCriteria.grossWeight?.to?.toString().trim()
  )
    count++;

  if (
    appliedFilterCriteria.netWeight?.from?.toString().trim() ||
    appliedFilterCriteria.netWeight?.to?.toString().trim()
  )
    count++;

  if (
    appliedFilterCriteria.totalDiamondWeight?.from?.toString().trim() ||
    appliedFilterCriteria.totalDiamondWeight?.to?.toString().trim()
  )
    count++;

  if (appliedFilterCriteria.category?.length)
    count += appliedFilterCriteria.category.length;
  if (appliedFilterCriteria.subCategory?.length)
    count += appliedFilterCriteria.subCategory.length;
  if (appliedFilterCriteria.collectionLine?.length)
    count += appliedFilterCriteria.collectionLine.length;
  if (appliedFilterCriteria.purity?.length)
    count += appliedFilterCriteria.purity.length;

  if (appliedFilterCriteria.searchText?.toString().trim()) count++;

  return count;
};

const AppBarComponent = ({ gridView = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [view, setView] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const ogFilter = useSelector((state) => state.ogFilter);
  const activeFilters = countActiveFilters(ogFilter);
  const [showFilter, setShowFilter] = useState(false);
  const puritiesMaster = useSelector((state) => state.purities) || [];
  // const ogFilter = useSelector((state) => state.ogFilter);
  // component level

  const masterData = {
    categories: useSelector((state) => state.categories) || [],
    subCategories: useSelector((state) => state.subCategories) || [],
    collectionLines: useSelector((state) => state.collectionLines) || [],
  };

  // Sync view with URL
  useEffect(() => {
    const pathname = location.pathname?.toString().toLowerCase();

    if (pathname?.includes("/cart")) {
      setView("cart");
    } else if (pathname?.includes("/logout")) {
      setView("logout");
    } else {
      setView("products"); // Default view
    }
  }, [location.pathname]);

  const handleViewChange = (newView, path) => {
    // if (view !== "filter") {
    setShowFilter(false);
    // }
    setView(newView);
    sessionStorage.setItem("view", newView); // Save to sessionStorage
    navigate(path);
  };

  return (
    <AppBar
      position="fixed"
      className="custom-app-bar"
      style={{
        position: "fixed",
        bottom: "0",
        left: "0",
        right: "0",
        top: "auto",
        zIndex: 1300, // Ensure it's on top
      }}
    >
      {showFilter ? (
        <SearchFilter
          masterData={masterData}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          puritiesMaster={puritiesMaster}
          ogFilter={ogFilter}
        />
      ) : null}

      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="icon-group">
          {/* Filter Button */}
          {view !== "cart" && masterData.categories.length ? (
            <IconButtonWithLabel
              icon={
                <Badge badgeContent={activeFilters} color="secondary">
                  {showFilter ? (
                    <FilterAltOffIcon />
                  ) : (
                    <FilterAltOutlinedIcon />
                  )}
                </Badge>
              }
              showLabel={!isMobile}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
              view={view}
              OGview="filterFields"
            />
          ) : null}

          {/* Products Button */}
          <IconButtonWithLabel
            icon={gridView ? <ViewList /> : <ViewModule />}
            label="Products"
            showLabel={!isMobile}
            onClick={() => handleViewChange("products", "")}
            view={view}
            OGview="products"
          />

          {/* Cart Button */}
          <IconButtonWithLabel
            icon={<CartIconButton />}
            label="Cart"
            showLabel={!isMobile}
            onClick={() => handleViewChange("cart", "cart")}
            view={view}
            OGview={staticViews.cart}
          />

          {/* Logout Button */}
          <IconButtonWithLabel
            icon={
              <MyHoverIcons
                muiIcon={
                  <PowerSettingsNewOutlinedIcon style={{ fontSize: "16px" }} />
                }
                muiIconHover={
                  <WindPowerOutlinedIcon style={{ fontSize: "16px" }} />
                }
              />
            }
            label="Logout"
            showLabel={!isMobile}
            onClick={() => {
              if (window.confirm("Confirm logout?")) {
                sessionStorage.clear();

                window.location.reload();
              }
            }}
            view={view}
            OGview={staticViews.logout}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

const CartIconButton = () => {
  const [previousQuantity, setPreviousQuantity] = useState(0);
  const [animate, setAnimate] = useState(false);

  const totalQuantity = useSelector((state) =>
    state.cart.items.reduce((a, b) => a + b.quantity, 0)
  );

  useEffect(() => {
    if (totalQuantity !== previousQuantity) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 500);
      setPreviousQuantity(totalQuantity);
    }
  }, [totalQuantity, previousQuantity]);

  return (
    <Badge
      badgeContent={totalQuantity}
      color="secondary"
      className={animate ? "animate" : ""}
    >
      <ShoppingCart />
    </Badge>
  );
};

// Reusable component to handle icon + label as a button with background
const IconButtonWithLabel = ({
  icon,
  label,
  showLabel,
  onClick,
  view,
  OGview,
}) => {
  return (
    <Button
      onClick={onClick}
      style={{
        backgroundColor:
          view === OGview ? enums.colorTheme.secondary : "#d2e3fa", // Button background color
        color:
          view === OGview
            ? enums.colorTheme.secondaryLight
            : enums.colorTheme.secondary,
        padding: "8px 16px",
        display: "flex",
        alignItems: "center",
        gap: "8px", // Space between icon and text
        textTransform: "none", // Disable uppercase text
        borderRadius: "8px",
      }}
    >
      {icon}
      {showLabel && <Typography variant="caption">{label}</Typography>}
    </Button>
  );
};

export default AppBarComponent;
